import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//获取卡片
export const getCardList = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBill/ChooseImages`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}